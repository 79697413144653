
// Product Constant Variable
export const FETCH_PRODUCTS_BEGIN_FILTER_VALUE = 'FETCH_PRODUCTS_BEGIN_FILTER_VALUE'
export const RECEIVE_PRODUCTS_FILTER_VALUE = 'RECEIVE_PRODUCTS_FILTER_VALUE'
export const FETCH_SINGLE_PRODUCT_FILTER_VALUE = 'FETCH_SINGLE_PRODUCT_FILTER_VALUE'

// Filter Constant Variable
export const COLOR_FILTER_VALUE = 'COLOR_FILTER_VALUE'
export const CATEGORY_FILTER_VALUE = 'CATEGORY_FILTER_VALUE'
export const SIZE_FILTER_VALUE = 'SIZE_FILTER_VALUE'
export const PRICE_FILTER_VALUE = 'PRICE_FILTER_VALUE'
export const SORT_BY_FILTER_VALUE = 'SORT_BY_FILTER_VALUE'
export const RATING_FILTER_VALUE = 'RATING_FILTER_VALUE'
export const SEARCH_FILTER_VALUE = 'FILTER_SEARCH'



